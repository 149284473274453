import React from "react"

const Hero = () => (
  <div className="container pt-2 pb-8 sm:pt-12 sm:pb-12 lg:pt-12 lg:pb-16">
    <h2 className="text-xl sm:text-4xl font-extrabold leading-tight tracking-tight text-gray-900">
      Better Game Better Life{" "}
      <span role="img" aria-label="heart" className="text-red-600">
        ❤️
      </span>
      <br />
      <span className="text-blue-600">
        Train your brain and have fun!
      </span>
    </h2>
  </div>
)

export default Hero
