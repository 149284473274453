import { graphql } from "gatsby"
import React from "react"
import Cards from "../components/Cards"
import Hero from "../components/Hero"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SiteMetadata title="Home" description="Word Games, Brain Games, and Puzzle Games from Blue Boat Game studio" />

      <Hero />

      <div className="bg-gray-100 py-12 lg:py-16">
        {data.game && data.game.nodes.length > 0 ? (
          <Cards items={data.game.nodes} />
        ) : (
          <div className="container">No games found.</div>
        )}
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    game: allContentfulGame {
      nodes {
        ...GameCard
      }
    }
  }
`
